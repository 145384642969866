import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { BURNETT_AUTH0_DOMAIN, BURNETT_AUTH0_CLIENT_ID} from './config';
import './index.scss';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={BURNETT_AUTH0_DOMAIN}
    clientId={BURNETT_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
    cookieDomain={".longjump.ai"}
  >
    <Router>
      <App />
    </Router>
  </Auth0Provider>
);
